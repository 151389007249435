<template>
  <form class="text-input-container" @submit.prevent="send">
    <textarea
      ref="textArea"
      class="input is-text-input"
      @input="autoHeight"
      :class="smallClass"
      :placeholder="$t('inbox.messages_placeholder')"
      v-model="value"
      @keydown.enter.exact.prevent
      @keyup.enter.exact="send"
      @keydown.enter.shift.exact="newline"
    ></textarea>
    <button class="button is-text-input" :class="smallClass">
      <small-loading-indicator v-if="isRequesting"></small-loading-indicator>
      <send-icon class="svg-icon send-icon-color" v-else></send-icon>
    </button>
  </form>
</template>

<script>
import SendIcon from "@/assets/icons/send.svg";
import SmallLoadingIndicator from "@/shared/components/common/loading/SmallLoadingIndicator";

export default {
  name: "InboxTextInput",

  components: {SendIcon, SmallLoadingIndicator},

  props: {
    isSmall: {
      type: Boolean,
      default: false,
    },

    isRequesting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: "",
      lastHeight: 0,
    };
  },

  computed: {
    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    send() {
      if (!this.isRequesting && this.value) {
        const value = this.value;
        this.$emit("send", value);
        this.value = "";
        this.$nextTick(() => this.autoHeight());
      }
    },

    setText(text) {
      this.value = text;
      this.autoHeight();
    },

    autoHeight() {
      const textArea = this.$refs.textArea;
      this.$nextTick(() => {
        textArea.style.height = textArea.style.minHeight;
        textArea.style.height = textArea.scrollHeight + "px";
        if (this.lastHeight !== textArea.scrollHeight) {
          this.lastHeight = textArea.scrollHeight;
          this.$emit("refresh-layout");
        }
      });
    },
  },
};
</script>
