<template>
  <div v-if="selectedUser" class="first-message-container" :class="smallClass" v-prevent-parent-scroll>
    <!-- FIRST MESSAGE VIEW-->
    <inbox-thread-banner :user="selectedUser" :is-small="isSmall"></inbox-thread-banner>
    <div v-if="firstMessageLoading">
      <list-loading-indicator :active="firstMessageLoading"></list-loading-indicator>
    </div>
    <div v-else class="first-message-placeholder has-text-secondary" :class="smallClass">
      {{ placeholderText }}
    </div>
    <inbox-text-input class="p-1" :is-small="isSmall" v-on:send="sendFirstMessage"></inbox-text-input>
  </div>
  <div class="create-thread-container" :class="smallClass" v-else>
    <!-- SELECT USERS VIEW-->
    <div class="search-bar-container" :class="smallClass" v-prevent-parent-scroll>
      <div class="columns is-mobile is-vcentered is-gapless">
        <div class="column is-narrow">
          <div class="message-to-text mr-1" :class="smallClass">
            {{ $t("inbox.message_to") }}
          </div>
        </div>
        <div class="column">
          <search-input
            v-model="searchQuery"
            :placeholder="$t('inbox.friends_search_placeholder')"
            border-radius="8px"
            :button-visible="false"
            :is-small="isSmall"
          ></search-input>
        </div>
      </div>
    </div>
    <div ref="userListElement" class="contact-list" :class="smallClass" v-prevent-parent-scroll>
      <template v-if="inboxUsers && inboxUsers.length">
        <div @click="selectUser(user)" v-for="user in inboxUsers" :key="user.user_identity_token">
          <user-list-item :user="user" :is-small="isSmall"></user-list-item>
        </div>
        <list-loading-indicator :active="isLoading"></list-loading-indicator>
      </template>
      <div v-else-if="showEmptyPlaceholder" class="has-text-centered has-text-secondary mt-3">
        <div v-if="searchQuery.length">
          {{ $t("inbox.empty_friends_search_placeholder") }}
        </div>
        <div v-else>
          {{ $t("inbox.empty_friends_placeholder") }}
        </div>
      </div>
      <div v-else>
        <list-loading-indicator :active="isLoading"></list-loading-indicator>
      </div>
    </div>
  </div>
</template>

<script>
import InboxThreadBanner from "@/web/components/inbox/InboxThreadBanner";
import SearchInput from "@/shared/components/SearchInput";
import InboxTextInput from "@/web/components/inbox/InboxTextInput";
import UserListItem from "@/web/components/inbox/InboxUserListItem";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import {mapActions, mapGetters, mapMutations} from "vuex";
import { throttle } from "@/shared/utils";
import {Constants} from "@/web/constants";

export default {
  name: "InboxCreateThread",

  components: {
    SearchInput,
    UserListItem,
    InboxThreadBanner,
    InboxTextInput,
    ListLoadingIndicator,
  },

  props: {
    isSmall: {
      type: Boolean,
      default: false,
    },

    userUuid: {
      type: String,
    },
  },

  mounted() {
    const userUuid = this.$route.params.userId || this.userUuid;
    if (userUuid) {
      this.selectedUser = this.getUserById(userUuid);
      if (!this.selectedUser) {
        this.loadUserProfile(userUuid)
          .then(result => {
            this.selectedUser = result;
          })
          .finally(() => {
            if (!this.selectedUser) {
              this.loadFriendsPage();
              this.addScrollListener();
            }
          });
      }
    } else {
      this.loadFriendsPage();
      this.addScrollListener();
    }
  },

  beforeDestroy() {
    this.removeScrollListener();
  },

  data() {
    return {
      selectedUser: null,
      searchQuery: "",
      scrollListener: null,
      firstMessageLoading: false,
    };
  },

  watch: {
    searchQuery: function (newVal) {
      if (newVal.length) {
        this.searchFriends(newVal);
      } else {
        this.clearSearchResult();
      }
    },
  },

  computed: {
    ...mapGetters("inboxUsers", ["getFriends", "isLoading"]),
    ...mapGetters("attendees", ["getUserById"]),

    inboxUsers() {
      return this.getFriends(this.searchQuery);
    },

    placeholderText() {
      const texts = Object.values(this.$t("inbox.first_message_placeholder"));
      return texts[Math.floor(Math.random() * texts.length)];
    },

    scrollThreshold: () => 200,

    showEmptyPlaceholder() {
      return !this.isLoading && this.inboxUsers && !this.inboxUsers.length;
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapActions("inboxUsers", ["loadFriendsPage", "searchFriends"]),
    ...mapActions("inboxThreads", ["checkIfThreadExists", "createThread"]),
    ...mapMutations("inboxUsers", ["clearSearchResult"]),
    ...mapActions("attendees", ["loadUserProfile"]),

    selectUser(user) {
      this.checkIfThreadExists({userId: user.user_identity_token}).then(result => {
        if (result.data) {
          const threadId = result.data.id;
          if (this.isSmall) {
            this.$emit("thread-selected", threadId);
          } else {
            this.$router.replace({name: Constants.ROUTE_INBOX_THREAD, params: {threadId}});
          }
        } else {
          this.removeScrollListener();
          this.selectedUser = user;
        }
      });
    },

    sendFirstMessage(message) {
      if (!this.firstMessageLoading && message.length) {
        this.firstMessageLoading = true;
        this.createThread({message: message, userIds: [this.selectedUser.user_identity_token]})
          .then(result => {
            if (result) {
              const threadId = result.response.data.id;
              if (this.isSmall) {
                this.$emit("thread-selected", threadId);
              } else {
                this.$router.replace({name: Constants.ROUTE_INBOX_THREAD, params: {threadId}});
              }
            }
          })
          .catch(err => {
            this.firstMessageLoading = false;
          });
      }
    },

    addScrollListener() {
      if (this.$refs.userListElement && this.scrollListener === null) {
        this.scrollListener = ev => {
          const friendsList = ev.target;
          let isOnBottomOfList = friendsList.scrollTop > friendsList.scrollHeight - friendsList.clientHeight - this.scrollThreshold;
          if (isOnBottomOfList) {
            this.onBottomOfScroll();
          }
        };
        this.$refs.userListElement.addEventListener("scroll", this.scrollListener);
      }
    },

    removeScrollListener() {
      if (this.scrollListener) {
        this.$refs.userListElement.removeEventListener("scroll", this.scrollListener);
        this.scrollListener = null;
      }
    },

    onBottomOfScroll: throttle(function (newVal) {
      this.loadFriendsPage();
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.first-message-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  justify-content: space-between;

  &.is-small {
    height: 370px;
  }
}

.create-thread-container {
  height: 100%;

  &.is-small {
    height: 370px;
  }
}

.search-bar-container {
  height: 100px;
  width: 100%;
  border-bottom: 1px solid var(--inbox-thread-banner-separator-color);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-small {
    height: 60px;
  }
}

.contact-list {
  overflow-y: auto;
  height: calc(100% - 100px);

  &.is-small {
    height: calc(100% - 60px);
  }
}

.message-to-text {
  font-size: 18px;
  color: var(--text-color-primary);

  &.is-small {
    font-size: 14px;
  }
}

.first-message-placeholder {
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;

  &.is-small {
    font-size: 18px;
  }
}
</style>
